import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            user: null
        };
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        clearUser(state) {
            state.user = null;
        }
    },
    actions: {
        authenticateUser({ commit }, data) {
            if (data.is_authenticated) {
                commit('setUser', data.user);
            }
        },
        logout({ commit }) {
            commit('clearUser');
        }
    },
    getters: {
        user: state => state.user
    }
});
