<template>
    <section class="ftco-section wrapper">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center mb-5">
                    <h2 class="heading-section">Configuration Tool</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-7 col-lg-5">
                    <div class="login-wrap p-4 p-md-5">
                        <div class="user-icon icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-user-o"></span>
                        </div>
                        <h3 class="text-center mb-4 form-text">Sign In</h3>
                        <div class="login-form">
                            <div class="form-group">
                                <input v-on:keyup.enter="signin" v-model="signin_data.username" type="text" class="form-control rounded-left" placeholder="Username" required>
                            </div>
                            <div class="form-group d-flex">
                                <input v-on:keyup.enter="signin" v-model="signin_data.password" type="password" class="form-control rounded-left" placeholder="Password" required>
                            </div>
                            <div class="form-group d-md-flex">
                                <div class="w-100 text-md-center">
                                    <label id="login-error-message" style="color: red; display: none;">Wrong username or password</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <button v-on:click="signin" class="form-control btn btn-primary rounded submit px-3">Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from '@/services/axios-config.js';

export default {
  data() {
    return {
      signin_data: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    async signin() {
      const error_message_lable = document.getElementById("login-error-message");
      error_message_lable.style.display = "none";

      try {
        const response = await axios.post('/signin', this.signin_data);
        if (response.status === 200) {
          this.$router.push('/');
        } else {
          error_message_lable.style.display = "block";
        }
      } catch (error) {
        if (error.response && error.response.status !== 200) {
          error_message_lable.style.display = "block";
        }
      }
    }
  },
};
</script>


