<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div class="row justify-content-center config-edit-form">
      <div class="col-5 mt-5">
        <div class="mb-4">
          <div class="mb-4">
            <label for="configurationSelect" class="form-label">Configuration</label>
            <select
                @change="loadVersions($event)"
                v-model="configuration"
                id="configurationSelect"
                class="form-select"
                aria-label="Configuration selector"
            >
              <option v-for="config in configurations" v-bind:value="config">{{ config }}</option>
            </select>
          </div>
          <div class="mb-4">
            <label for="versionSelect" class="form-label">Version</label>
            <select
                @change="loadConfig()"
                v-model="version"
                id="versionSelect"
                class="form-select"
                aria-label="Version selector">
              <option v-for="version in versions" v-bind:value="version">{{ version }}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-show="isShowEditor" class="col-11 mt-5">
        <div class="col-12 mt-5 mb-5 text-center">
          <button @click="saveConfig()" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'EditConfig',
  components: {
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: false,
      config: '',
      old_config: {},
      version: '',
      configuration: '',
      versions: [],
      configurations: [],
    }
  },
  methods: {
    async loadConfig() {
      if (this.version && this.configuration) {
        await axios.get('/manage/' + this.configuration, {
          params: {
            version: this.version
          }
        })
            .then((response) => {
              this.config = response.data.config;
              this.old_config = response.data.config;
            })
            .catch((error) => {
              console.error('Error fetching config:', error);
            });
      }
    },

    loadConfigurations() {
      axios.get('/configurations')
          .then((response) => {
            this.configurations = response.data.configurations;
          })
          .catch((error) => {
            console.error('Error fetching configurations:', error);
          });
    },

    loadVersions(event) {
      axios.get('/versions', {
        params: {
          configuration: event.target.value
        }
      })
          .then((response) => {
            this.versions = response.data.versions;
          })
          .catch((error) => {
            console.error('Error fetching versions:', error);
          });
    },

    saveConfig() {
      const data = {
        version: this.version,
        new_config: this.config,
        old_config: this.old_config,
      };

      axios.put('/manage/' + this.configuration, data)
          .then((response) => {
            if (response.status === 200) {
              alert('Configuration saved');
            } else {
              alert('Error saving configuration');
            }
          })
          .catch((error) => {
            console.error('Error saving configuration:', error);
            alert('Error saving configuration');
          });
    }
  },
  computed: {
    isShowEditor() {
      // Don't show the editor while data is loading
      if (Object.keys(this.old_config).length === 0) {
        return false;
      }
      return true;
    },
    editorHeight() {
      if (Object.keys(this.config).length === 0) {
        return 5;
      }
      // Calculate the height of the editor based on the number of lines in the config
      if (typeof this.config === 'string' || this.config instanceof String) {
        return (this.config.split("\n").length + 5);
      }
      return 5;
    }
  },
  async beforeMount() {
    this.isLoading = true;
    await this.loadConfigurations();
    this.isLoading = false;
  }
}
</script>

<style scoped>
</style>
