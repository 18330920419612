<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div class="row justify-content-center config-create-form">
      <div class="col-5 mt-5">
        <div class="mb-4">
          <label for="configurationSelect" class="form-label">Select configuration</label>
          <select
              @change="loadVersions($event)"
              v-model="configuration"
              id="configurationSelect"
              class="form-select"
              aria-label="Configuration selector"
          >
            <option v-for="config in configurations" v-bind:value="config">{{ config }}</option>
          </select>
        </div>
        <div v-show="versions.length > 0" class="mt-4 mb-4">
          <label for="existedVersionsList" class="form-label">Versions list:</label>
          <ul id="existedVersionsList" class="list-group">
            <li v-for="version in versions" v-bind:value="version" class="list-group-item">{{ version }}</li>
          </ul>
          <hr class="my-4">
        </div>
        <div v-show="configuration !== ''" class="mb-4">
          <label for="createNewVersion" class="form-label">Create new version:</label>
          <input type="email" class="form-control" id="createNewVersion" aria-describedby="emailHelp" v-model="version">
          <div id="emailHelp" class="form-text">Use only digits and dots. Example: 3.2.1</div>
        </div>
      </div>
      <div v-show="configuration !== ''" class="col-11 mt-5">
        <label for="json-editor" class="form-label">Configuration editor:</label>
        <textarea id="json-editor" class="json-editor w-100" v-model="config" v-bind:rows="editorHeight"></textarea>
        <div class="col-12 mt-5 text-center">
          <button @click="saveConfig()" class="btn btn-primary" :disabled="!version">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'CreateConfig',
  components: {
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: true,
      config: '',
      version: '',
      configuration: '',
      versions: [],
      configurations: [],
    }
  },
  methods: {
    async loadConfigurations() {
      await axios.get('/configurations')
          .then((response) => {
            this.configurations = response.data.configurations;
          })
          .catch((error) => {
            console.error('Error fetching configurations:', error);
          });
    },

    loadVersions(event) {
      axios.get('/versions', {
        params: {
          configuration: event.target.value
        }
      })
          .then((response) => {
            this.versions = response.data.versions;
          })
          .catch((error) => {
            console.error('Error fetching versions:', error);
          });
    },

    saveConfig() {
      const data = {
        configuration: this.configuration,
        version: this.version,
        config: this.config,
      };

      axios.post('/manage/' + this.configuration, data)
          .then((response) => {
            if (response.status === 201) {
              alert('Configuration saved');
              this.$router.push('/');
            } else {
              alert('Error saving configuration');
            }
          })
          .catch((error) => {
            console.error('Error saving configuration:', error);
            alert('Error saving configuration');
          });
    }
  },
  computed: {
    editorHeight() {
      if (this.config.length === 0) {
        return 5;
      }
      // Calculate the height of the editor based on the number of lines in the config
      if (typeof this.config === 'string' || this.config instanceof String) {
        return (this.config.split("\n").length + 5);
      }
      return 5;
    }
  },
  async beforeMount() {
    this.isLoading = true;
    await this.loadConfigurations();
    this.isLoading = false;
  },
}
</script>

<style scoped>
</style>
