<template>
  <div id="editor" ref="editor"></div>
</template>

<script setup>
window.MonacoEnvironment = {
  getWorkerUrl: function(moduleId, label) {
    if (label === 'json') {
      return './jsonWorker.js';
    }
    //return './js/editor.worker.bundle.js';
  }
};

import * as monaco from 'monaco-editor'
import { ref, onMounted } from 'vue'

const editor = ref()

onMounted(() => {
  monaco.editor.create(editor.value, {
    value: `{"foo": "bar"}`,
    language: 'json',
    json: {
      validate: true // Explicitly enable validation
    }
  });
})
</script>

<style scoped>
#editor {
  width: 100vw;
  height: 100vh;
}
</style>
