import {createRouter, createWebHistory} from 'vue-router';
import Login from '@/components/Login.vue';
import EditConfig from '@/components/EditConfig.vue';
import CreateConfig from '@/components/CreateConfig.vue';
import Test from '@/components/Test.vue';
import axios from '@/services/axios-config.js';
import store from "@/store/index.js";

const routes = [
    {
        path: '/',
        redirect: '/edit',
        meta: { title: 'Edit configuration' }
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
        meta: { title: 'Test' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'Login' }
    },
    {
        path: '/edit',
        name: 'EditConfig',
        component: EditConfig,
        meta: {
            requiresAuth: true,
            title: 'Edit configuration'
        }
    },
    {
        path: '/create',
        name: 'Create',
        component: CreateConfig,
        meta: {
            requiresAuth: true,
            title: 'Create configuration'
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            const response = await axios.get("/auth");
            if (response.status === 200 && response.data.is_authenticated) {
                await store.dispatch('authenticateUser', response.data);
                next();
            } else {
                console.log("User is not authenticated or unexpected response");
                next({ name: "Login" });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not authorized");
                next({ name: "Login" });
            } else if (error.response && error.response.status === 400) {
                console.log("Wrong credentials");
                next({ name: "Login" });
            } else {
                console.error("Error checking authentication:", error);
                next({ name: "Login" });
            }
        }
    } else {
        next();
    }

    // Setting the page title based on the route's meta information
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else {
        document.title = 'Builds Tool';
    }

});

export default router;
